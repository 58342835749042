/* Base styles */
body {
  /* Use smooth scrolling instead of auto for a better experience */
  scroll-behavior: smooth;
  overflow-x: hidden;
  cursor: default;
}

/* Remove snap scrolling and allow natural scrolling */
.app-container {
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  /* Remove scroll snap type for natural scrolling */
  scroll-snap-type: none;
  display: flex;
  flex-direction: column;
  /* Use smooth scrolling */
  scroll-behavior: smooth;
}

/* Container for Framer Motion animations */
.sections-container {
  flex: 1;
  display: flex;
  flex-direction: column;
}

/* Make sections full height but remove snap alignment */
.section {
  min-height: 100vh;
  position: relative; /* Add for positioning context with animations */
  transition: all 0.3s ease-in-out; /* Faster transition for more immediate feedback */
  /* Remove scroll-snap-align */
}

/* Reveal animation container */
.reveal-container {
  overflow: hidden;
  position: relative;
}

/* For scroll-triggered animations */
.scroll-animated {
  visibility: visible; /* Will be animated by Framer Motion */
}

/* Prevent text cursor on interactive elements */
a, button, .btn, .card, .carousel-item, nav, header, footer, .section, .container {
  cursor: pointer;
  user-select: none;
}

/* Text elements should still show the text cursor */
p, h1, h2, h3, h4, h5, h6, span, li {
  cursor: default;
  user-select: text;
}

/* Hero image spinning light effects */
.hero-img {
  position: relative;
}

@keyframes glowing {
  0% {
    box-shadow: 0 0 10px 0px rgba(56, 178, 172, 0.5);
  }
  50% {
    box-shadow: 0 0 20px 5px rgba(56, 178, 172, 0.7);
  }
  100% {
    box-shadow: 0 0 10px 0px rgba(56, 178, 172, 0.5);
  }
}

.hero-img img {
  position: relative;
  z-index: 1;
  animation: glowing 3s ease-in-out infinite;
}

/* Animation performance optimizations */
.animate-spin-slow, 
.animate-spin-reverse,
.animate-pulse {
  will-change: transform, opacity;
  backface-visibility: hidden;
  transform: translateZ(0);
}

.hero-img [class*='rounded-full'] {
  will-change: transform;
  transform: translateZ(0);
}

/* Scrollbar styling */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar-thumb {
  background: rgba(56, 178, 172, 0.5);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(56, 178, 172, 0.8);
}

/* Smooth scrolling without snap behavior */
html {
  scroll-behavior: smooth;
}

/* Animation classes */
.fade-in {
  animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Navigation styling */
.mobile-nav {
  @apply block lg:hidden fixed top-0 py-2 w-full h-full bg-primary-dark duration-300 z-50;
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
}

.mobile-nav.open {
  transform: translateX(0);
}

.mobile-nav ul {
  @apply flex flex-col items-center justify-center h-full space-y-8;
}

.mobile-nav li a {
  @apply text-xl font-medium text-neutral-100 hover:text-accent transition-colors;
}

nav li a {
  @apply px-4 py-2 text-base font-medium relative;
}

nav li a::after {
  content: '';
  @apply absolute bottom-0 left-0 w-0 h-0.5 bg-accent transition-all duration-300;
}

nav li a:hover::after {
  @apply w-full;
}

/* Section styling */
.section-title {
  @apply text-3xl lg:text-4xl font-bold mb-8 relative inline-block;
}

.section-title::after {
  content: '';
  @apply absolute -bottom-2 left-0 w-2/3 h-1 bg-accent;
}

/* Card and project styling */
.card {
  @apply bg-secondary rounded-lg shadow-custom overflow-hidden transition-transform duration-300;
}

.card:hover {
  transform: translateY(-5px);
}

/* Modal styling */
.modal-overlay {
  @apply fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50 p-4;
  backdrop-filter: blur(4px);
}

.modal-content {
  @apply bg-secondary rounded-lg shadow-lg max-w-4xl w-full max-h-[90vh] overflow-auto relative;
}

.modal-inner {
  @apply p-6;
}

.modal-close-button {
  @apply absolute top-4 right-4 p-2 text-neutral-400 hover:text-white bg-neutral-800 rounded-full transition-colors;
}

/* Carousel improvements */
.carousel-container {
  @apply rounded-xl overflow-hidden;
}

.carousel-item {
  @apply rounded-lg overflow-hidden shadow-lg;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #aeff74;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.bg-primary {
  background: rgb(12 74 110);
}

.bg-secondary {
  background: rgb(30 58 138);
}

.bg-accent {
  background: rgb(34 211 238);
}

.text-accent {
  color: rgb(34 211 238);
}

.close-icon {
  color: rgb(78, 82, 83);
  font-size: 20px;
}

/* Scroll indicator */
.scroll-indicator {
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 50px;
  height: 50px;
  background-color: rgba(56, 178, 172, 0.3);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 40;
  backdrop-filter: blur(5px);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.scroll-indicator.visible {
  opacity: 1;
}

.scroll-indicator .indicator-dot {
  width: 6px;
  height: 6px;
  background-color: rgba(56, 178, 172, 1);
  border-radius: 50%;
  animation: scrollPulse 2s infinite;
}

@keyframes scrollPulse {
  0% {
    transform: translateY(0);
    opacity: 0.5;
  }
  50% {
    transform: translateY(10px);
    opacity: 1;
  }
  100% {
    transform: translateY(0);
    opacity: 0.5;
  }
}