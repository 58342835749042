@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&family=Poppins:wght@400;500;600;700&family=Fira+Code:wght@400;500&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    scroll-behavior: smooth;
    height: 100%;
    overflow-x: hidden;
    /* Remove mandatory scroll snap */
    scroll-snap-type: none;
  }

  body {
    @apply bg-primary text-neutral-100 font-sans;
    height: 100%;
    overflow-x: hidden;
    -webkit-tap-highlight-color: transparent; /* Prevents flash on tap on mobile */
  }

  /* Prevent text selection on interactive elements */
  a, button, .btn, img, svg, .card, .carousel-item, nav, header, footer, .carousel {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-user-drag: none;
  }

  /* Remove focus outline except for accessibility */
  *:focus {
    outline: none;
  }
  *:focus-visible {
    outline: 2px solid theme('colors.accent.DEFAULT');
  }

  h1, h2, h3, h4, h5, h6 {
    @apply font-display font-semibold;
  }

  h1 {
    @apply text-4xl md:text-5xl lg:text-6xl leading-tight;
  }

  h2 {
    @apply text-3xl md:text-4xl leading-snug;
  }

  h3 {
    @apply text-2xl md:text-3xl leading-snug;
  }

  p {
    @apply text-base md:text-lg leading-relaxed;
  }
}

@layer components {
  .btn {
    @apply inline-flex items-center justify-center px-6 py-3 rounded-md font-semibold transition-all duration-200 text-sm md:text-base;
    cursor: pointer;
  }

  .btn-primary {
    @apply bg-accent text-white hover:bg-accent-light shadow-md;
  }

  .btn-outline {
    @apply border-2 border-accent text-accent hover:bg-accent hover:text-white;
  }

  .section {
    @apply min-h-screen py-16 md:py-20 lg:py-24 flex flex-col justify-center;
    padding-top: 80px; /* Add padding to account for fixed header */
    /* Remove scroll snap align */
  }

  .container {
    @apply px-4 sm:px-6 lg:px-8 mx-auto max-w-7xl;
  }
}

/* Remove snap scrolling utilities that might be causing issues */